import React from 'react'
import currency from "currency.js";
import ProductCardButtons from "./ProductCardButtons";
import {Button, Icon} from "semantic-ui-react";

const ProductCard = ({product, productInExternalCart, onProductSelect, onProductQuantityChange, onProductRemove, allowMultiple= false, tapToExpand, disableActions}) => {
    const {name, retail_price, discounted_price, imageAlt, image} = product

    const formattedRetailPrice = currency(retail_price).format()
    const formattedDiscountedPrice = currency(discounted_price).format()

    const actionArgs = { product, productInExternalCart, onProductSelect, onProductRemove, allowMultiple, disableActions }

    const handleProductTap = async _ => {
        !disableActions && app.trigger(app.events.SHOW_POPUP, {
            content_component: <ProductInformation {...{
                product,
                onProductSelect,
                onProductRemove,
                productInExternalCart,
                onProductQuantityChange,
                allowMultiple
            }} />,
            noCloseBtn: true,
            noHeader: true,
        })
    }

    return (
        <div
            key={product.id}
            className={'relative min-h-full flex flex-col justify-between overflow-hidden rounded-lg border bg-white hover:cursor-pointer'} onClick={_ => tapToExpand && handleProductTap()}>
            {discounted_price && (
                <span className={'z-50 absolute top-2.5 right-2.5 text-white bg-red-600 rounded-xl px-2 py-1 text-xs'}>SALE</span>
            )}
            <div className={'px-4 py-5 sm:p-6 min-h-full flex flex-col justify-between'}>
                <div className="flex flex-col mb-2 gap-1">
                    <div className="relative overflow-hidden rounded-lg mb-2">
                        <img
                            alt={imageAlt}
                            src={image}
                            className="object-contain max-h-20 lg:max-h-auto w-full object-center select-none"
                        />
                    </div>
                    <span className="text-sm text-gray-600 line-clamp-3">{name}</span>
                </div>
                <div className={'flex flex-col gap-2'}>
                    {discounted_price
                        ? <div className={'flex gap-2'}>
                            <span className="text-sm text-gray-600 line-through">{formattedRetailPrice}</span>
                            <span className="text-sm text-red-600">{formattedDiscountedPrice}</span>
                        </div>
                        : <span className="text-sm text-gray-600">{formattedRetailPrice}</span>
                    }
                    {!disableActions && <ProductCardButtons {...actionArgs}/>}
                </div>
            </div>
        </div>
    )
}

const ProductInformation = (props) => {
    const {product, onProductSelect, onProductRemove, productInExternalCart, onProductQuantityChange, allowMultiple, onClose} = props

    const handleProductSelect = () => {
        onProductSelect(product)
        onClose()
    }

    const handleProductRemove = () => {
        onProductRemove(product)
        onClose()
    }

    const actionArgs = { product, productInExternalCart, onProductSelect: handleProductSelect, onProductQuantityChange, onProductRemove: handleProductRemove, allowMultiple: allowMultiple }

    return (
        <div>
            <Icon name={'close'} onClick={onClose} className={'absolute top-2 right-2 text-gray-600 hover:text-gray-800 cursor-pointer'}/>
            <div className={'p-2 grid grid-cols-2 gap-4'}>
                <div className={'col-span-2 sm:col-span-1'}>
                    <img
                        alt={product.name}
                        src={product.image}
                        className="my-auto object-contain max-h-auto w-full object-center"
                    />
                </div>
                <div className={'flex flex-col gap-2 my-auto col-span-2 sm:col-span-1'}>
                    <h4>{product.name}</h4>
                    <p>{currency(product.price).format()}</p>
                    <ProductCardButtons {...actionArgs}/>
                </div>
            </div>
        </div>

    )
}

export default ProductCard