import React from "react";
import { Popup, Icon } from "semantic-ui-react";
import evermed_model from "../../models/evermed_model";
import { useAsync } from "../Hooks/useAsync";
import { useDisclosure } from "../Hooks/useDisclosure";

const labels = {
    lineStatus: "Order Status",
    packageStatus: "Delivery Status",
    holdReason: "Hold Reason",
    rejectReason: "Reject Reason",
    comment: "Comment",
    packageDeliveryProvider: "Delivery Partner",
    packageTrackingCode: "Tracking Code",
    packageTrackingUrl: "Tracking Url",
    estimatedEarliestDeliveryDate: "Earliest delivery date",
    estimatedLatestDeliveryDate: "Latest delivery date",
    packageReadiedAt: "Readied At",
    packageDispatchedAt: "Dispatched At",
    packageDeliveredAt: "Delivered At",
}

const EvermedOrderDetails = ({ orderNumber }) => {
    const { isOpen, onToggle } = useDisclosure()

    const { data, loading, error } = useAsync(async () => isOpen === true && await evermed_model.getOrderStatus({ orderNumber }), [isOpen])
    const orderLines = data?.order?.lines || []

    return (
        <Popup
            trigger={<Icon name="magnify" onClick={onToggle} />}
            open={isOpen}
            position="left center"
            className={'!max-w-auto'}
        >
            <div className={'flex justify-between'}>
                <h4 className={''}>Evermed order details</h4>
                <Icon link name="close" style={{ float: "right" }} onClick={onToggle} />
            </div>
            {loading && <div><Icon loading name='spinner' size='small' /> <span>Loading data from Evermed</span></div>}
            {!loading && error && <p className={'text-red-500'}>Could not load the order from Evermed</p>}
            {!loading && !!orderLines.length &&
                <Popup.Content>
                    <div className={'flex flex-col space-y-3 max-h-[500px] overflow-y-scroll'}>
                        {orderLines.map((line) => {
                            const product = line.prescriptionToken ? 'Medication' : line.productVariantType
                            return (
                                <div>
                                    <h5 className="m-1 underline">{product}</h5>
                                    <div>
                                        {Object.keys(labels).map(key =>
                                            line[key] && (
                                                <p key={key} className="m-1">
                                                    <strong>{labels[key]}:</strong> {line[key]}
                                                </p>
                                            )
                                        )}
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                </Popup.Content>
            }
        </Popup>
    );
};

export default EvermedOrderDetails;
