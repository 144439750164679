import React from 'react';

const PromoTermsAndConditions = ({ n }) => (
	<div className="terms">
		{n === "welcome-back" && <p>This offer (WELCOME5BACK) can only be used once. This offer cannot be redeemed on medication cost or delivery of prescriptions and cannot be used in conjunction with any other offer or other promotion. We reserve the right to refuse redemption in the event of unauthorised, ineligible or inappropriate use of offer and to modify or cancel the offer at any time.</p>
		|| n === "new-inactive" && <p>New users only. This offer (EMPTY5OFF) cannot be redeemed on medication cost or delivery of prescriptions and cannot be used in conjunction with any other offer or other promotion. We reserve the right to refuse redemption in the event of unauthorised, ineligible or inappropriate use of offer and to modify or cancel the offer at any time.</p>}
	</div>
);

export default PromoTermsAndConditions
