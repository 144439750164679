//	------------------------	------------------------	------------------------
//	Description: wrapper for the GARBAGE js barcode react wrapper just to get autofit
//  width and height are broken in the react wrapper and cannot be set to say 100%
//  Version: 1.0.0
//  Updates: 
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

import React, { Component } from 'react'

//	------------------------	------------------------	------------------------
//	Globals
//	------------------------	------------------------	------------------------

import RBarcode from 'react-barcode'

//	------------------------	------------------------	------------------------
//	Classes
//	------------------------	------------------------	------------------------

class BarCode extends Component {

    constructor(props) {

        super(props)

        this.set_ref = React.createRef()

        this.state = { viewBox: '0 0 100 10' }

    }

    //	------------------------	-asda-----------------------	------------------------

    componentDidMount() {
        
        this.handle_barcode_size()

    }

    componentDidUpdate() {

        this.handle_barcode_size()

    }

    //	------------------------	------------------------	------------------------
    //  UI
    //	------------------------	------------------------	------------------------

    render() { // Wrapping context

        const scid = this.props.value || 'No SCID'

        return (
            <svg viewBox={this.state.viewBox}> {/* This is the magic bit. Makes it autosize */}
                <RBarcode ref={d => { this.ref = d }} value={scid} renderer='svg' margin={0} width={2} height={100} format='CODE128' displayValue={false} />
            </svg>
        )
    }

    //	------------------------	------------------------	------------------------

    handle_barcode_size() {

        const new_viewBox = this.ref?.refs?.renderElement?.attributes?.viewBox?.value
        if (new_viewBox && this.state.viewBox != new_viewBox) this.setState({ viewBox: new_viewBox }) // Only update when needed. Otherwise infinite loop here

    }

}

//	------------------------	------------------------	------------------------
//	Exports
//	------------------------	------------------------	------------------------

export default BarCode
