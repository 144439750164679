import React from 'react'
import { cls } from '../NUI/utils'

const SelectableCard = ({isSelected, isInvalid, isDisabled, onSelect, className, cardArgs, children}) => {
    const handleKeyDown = (event) => {
        if (!isDisabled && (event.key === 'Enter' || event.key === ' ')) {
            event.preventDefault();
            onSelect();
        }
    };

    return (
        <div
            onClick={isDisabled ? null : onSelect}
            onKeyDown={handleKeyDown}
            tabIndex={isDisabled ? -1 : 0}
            role="button"
            aria-pressed={isSelected}
            aria-disabled={isDisabled}
            className={cls(
                'w-full p-1 px-3 border-[1px] rounded-lg text-sm hover:cursor-pointer select-none',
                isDisabled && 'text-gray-400' || isSelected && 'border-blue-400 bg-blue-50' || isInvalid && 'border-red-400 bg-red-50 text-red-600' || 'border-gray-400',
                className
            )}
            {...cardArgs}
        >
            {children}
        </div>
    );
};
export default SelectableCard
