import React from 'react'
import {Button} from "semantic-ui-react";
import {useOutletContext} from "react-router-dom";

// Need to fix this up. This is getting needlessly complex
const ProductCardButtons = ({product, productInExternalCart, onProductSelect, onProductRemove, allowMultiple}) => {
    const {cart, cartDispatch} = useOutletContext() || {}

    const cartItemQty = cart?.items?.find(item => item.id === product.id)?.qty
    const isProductInCart = productInExternalCart || !!cartItemQty

    const handleProductSelect = onProductSelect ? onProductSelect : (product) => {
        cartDispatch({type: 'ADD', payload: product})
    }

    const handleProductQuantityChange = (product) => {
        cartDispatch({type: 'UPDATE', payload: product})
    }

    const handleProductRemove = onProductRemove ? onProductRemove : (product) => {
        cartDispatch({type: 'REMOVE', payload: product})
    }

    if(isProductInCart && allowMultiple){
        return (
            <div className={'bg-blue-100 rounded-full h-10 flex flex-row px-1 justify-between items-center select-none'}>
                <button
                    className={'h-8 w-8 rounded-full bg-white'}
                    onClick={e=> {
                        e.stopPropagation();
                        handleProductQuantityChange({...product, qty: cartItemQty - 1});
                    }}
                >
                    -
                </button>
                <p className={'m-0 text-xs'}>Qty: {cartItemQty}</p>
                <button
                    className={'h-8 w-8 rounded-full bg-white'}
                    onClick={e=> {
                        e.stopPropagation();
                        handleProductQuantityChange({...product, qty: cartItemQty + 1})}
                    }
                >
                    +
                </button>
            </div>
        )
    } else if (isProductInCart) {
        return <Button
            size={'tiny'} color={'green'} className={'w-full'}
            onClick={e => {
                e.stopPropagation()
                handleProductRemove(product)
            }}
        >Added</Button>
    } else {
        return <Button
            size={'tiny'}
            className={'w-full'}
            color={'blue'}
            onClick={e => {
                e.stopPropagation()
                handleProductSelect({...product, qty: 1})
            }}
        >
            Add Item
        </Button>
    }
}
export default ProductCardButtons