import React from "react";

import {
    PayPalScriptProvider,
    usePayPalScriptReducer,
    PayPalButtons,
} from "@paypal/react-paypal-js";
import API_service from "../../../../xAppLib/providers/API_service";

const PaypalButton = (props) => {
    return (
        <>
            <PayPalButtons
                fundingSource="paypal"
                disabled={props.disabled}
                style={{ color: "blue", label: "pay", height: props.btnHeight ? props.btnHeight : 42 }}
                createOrder={() => API_service.load_data( 'paypal/createOrder', { purchaseAmount: props.purchaseAmount } ).then((res) => res.order.id)}
                onApprove={(data, actions) => {
                    props.paypalOrderDetails.current = {...data, approvedDateTime: new Date()}
                    props.onConfirm()
                }}
                onCancel={({data, actions}) => {
                    // Don't report cancel as error = https://developer.paypal.com/sdk/js/reference/
                }}
            />
        </>
    );
};

export default PaypalButton;
