import API_service from "../xAppLib/providers/API_service";

const handleErrorResponse = (res) => {
    console.log(res)
    return {...res, res: 'err', error: res.error || res || 'Internal server error'}
}

export const fetchProducts = async (params = {}) => {
    return await API_service.api_promise('ecommerce/getProducts', params)
        .then(res => res)
        .catch(handleErrorResponse)
}

export const fetchSuggestedProducts = async (params) => {
    return await API_service.api_promise('ecommerce/getSuggestedProducts', params)
        .then(res => res)
        .catch(handleErrorResponse)
}

export const fetchCategories = async () => {
    return await API_service.api_promise('ecommerce/getCategories')
        .then(res => res)
        .catch(handleErrorResponse)
}

export const fetchFulfilmentMethods = async (payload) => {
    return await API_service.api_promise('ecommerce/getFulfilmentOptions', payload)
        .then(res => res)
        .catch(handleErrorResponse)
}

export const submitOrder = async (payload) => {
    return API_service.api_promise('ecommerce/submitOrder', payload, )
        .then(res => res)
        .catch(handleErrorResponse)
}

export const fetchOrder = async (payload) => {
    return API_service.api_promise('ecommerce/fetchOrder', payload)
        .then(res => res)
        .catch(handleErrorResponse)
}