import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Loader, Item, Modal, Checkbox } from 'semantic-ui-react';
import moment from 'moment-timezone';
import API_service from 'xAppLib/providers/API_service';
import { DataConnector, ListBasic, ListPagination } from 'xAppLib/DataTable';
import scripts_list_model from 'models/scripts_list_model';
import user_model from 'models/user_model';
import med_model from 'models/med_model';
import PathoResultsPatientReport from 'views/patho/PathoResultsPatientReport';
import { PreviewDocument, viewScriptUrl } from '../UIelems/PreviewDocument';
import { FRIENDLY_DATETIME_DAY_MONTH } from '../booking/formatting';
import { getClientBookingTimezone } from '../booking/shared';
import QRCode from 'qrcode.react';
import BarCode from 'views/UIelems/BarCode';
import SearchPharm from 'views/UIelems/SearchPharm';

const pdf_types = {
	pathoreq: 'Pathology Request',
	medcert: 'Medical Certificate',
	docrefr: 'Doctor Referral',
	doclett: 'Doctor Letter',
	sleeprefr: 'Doctor Sleep Referral',
	medimgreq: 'Medical Imaging'
};

export default function ScriptsHistory({ uid, refresh, onReminderClick, isReminderView = false }) {
	const { sid } = useParams();
	const [pageSize, setPageSize] = useState(50);
	const [page, setPage] = useState(1);
	const [view_escript, setViewEscript] = useState();
	const [state, setState] = useState({});

	return (
		<>
			<DataConnector
				refresh={refresh}
				pageSize={pageSize}
				page={page}
				data={() => {
					return new Promise((resolve, reject) => {
						scripts_list_model.load_scripts_list(
							{
								type: 'pts',
								total_count: true,
								get_stats: true,
								limit: pageSize,
								offset: (page - 1) * pageSize,
								filters: {
									sid,
									uid,
									...(isReminderView && { escr_only: true }),
								}
							},
							(results) => {
								if (results.res == 'ok') {
									resolve({ data: results.listScripts, total: results.total });
								}
							}
						);
					});
				}}
			>
				{(data, loading, pages, total, pageSize) => {
					if (loading) return <Loader active inline {...isReminderView && { className: "after:!border-t-gray-500", inline: undefined }}  />;
					if (total == 0)
						return (
							<div style={{ marginTop: '20px' }}>
								<p>Your script requests history is empty.</p>
							</div>
						);

					const timeRecent = moment().add(-2, 'hours');
					const has_profiles = app.user?.profs?.length > 1;
					return (
						<React.Fragment>
							<Item.Group divided relaxed className="history-list">
								<ListBasic data={data} loading={loading} itemsPerRow={1}>
									{(row, index) => {
										const add_tm = moment(row.add_tm, 'D/M/YY HH:mm:ss');
										const escript = row.epresc__scid;

										const bookingTimeZone = getClientBookingTimezone();

										const appointmentStart = row.appointment?.active && row.appointment.start_time ? moment(row.appointment.start_time).tz(bookingTimeZone) : null;

										return (
											<Item key={row.sid} className={`hist-item`} data-testid="hist-item" style={(!row.is_script && { backgroundColor: 'rgb(254 232 232)', borderTop: 0 }) || {}}>
												<Item.Content className="description">
													<Item.Header>
														{/* do we need this?? scripts_list_model.type_name(row.script_type) */}
														<ListBasic.Cell as={sid ? 'h2' : 'span'} row={row} index={index} path="med_db_data__name" data-testid="item-header" />
														&nbsp; <ListBasic.Cell hideIfEmpty className="size" as={'span'} row={row} index={index} path="med_db_data__size" />
														{row.med_db_data &&
															Object.keys(med_model.det_fields_show_user())
																.filter((k) => !['name', 'size'].includes(k) && row.med_db_data[k])
																.map((k, i) => (
																	<span key={'med_data' + i} className="size capitalize">
																		&nbsp;&nbsp; / {med_model.det_fields_show_user()[k]}: {row.med_db_data[k]} &nbsp;
																	</span>
																))}
													</Item.Header>
													<Item.Meta className="dates" style={{ textTransform: 'uppercase' }} data-testid="label-dates">
														{has_profiles && !uid && (
															<>
																{row.spd_data.first_name} {row.spd_data.last_name} –{' '}
															</>
														)}{' '}
														{row.appointment?.active ? 'Added ' : ''} {add_tm.format('MMM D, YYYY')}
														{/*(row.med_db_data__days_limit) && <Item.Meta><em>Expires {moment(row.add_tm,"D/M/YY HH:mm:ss").add(row.med_db_data__days_limit,'days').format('MMM D, YYYY')}</em></Item.Meta>*/}
													</Item.Meta>
													{appointmentStart &&
														(appointmentStart.isSameOrAfter(timeRecent) ? (
															<Item.Description>
																Appointment on <strong>{appointmentStart.format(FRIENDLY_DATETIME_DAY_MONTH)}</strong> <small>({bookingTimeZone} time)</small> <sup>*</sup>
																<br />
																<small>* Appointments times are approximate and may vary. Please allow for additional time if necessary.</small>
															</Item.Description>
														) : (
															<Item.Description>
																Appointment: <strong>{appointmentStart.format(FRIENDLY_DATETIME_DAY_MONTH)}</strong> <small>({bookingTimeZone} time)</small>
															</Item.Description>
														))}
													<ListBasic.Cell hideIfEmpty as={Item.Content} row={row} index={index} path="org__name">
														{(value, row, index) => {
															const escript = !!row.epresc__escr;
															const f =
																(row.script_type == 'pharm' && 'Requested on terminal at') ||
																(['medcons', 'exprcons', 'qualcons'].includes(row.script_type) && 'Preordered script' + (escript ? '' : ' sent to')) ||
																(row.script_type == 'doccons' && 'Scripts to send to') ||
																(row.script_type == 'docscr' && 'Doctor consultation script' + (escript ? '' : ' sent to')) ||
																(['medbuy', 'medclick', 'exprbuy', 'exprclick', 'qualbuy', 'qualclick'].includes(row.script_type) && 'Purchased from') ||
																(row.script_type == 'cosm' && 'Cosmetic treatment at') ||
																(row.script_type == 'medsub' && 'Medication supplied by');

															return (
																(f && (
																	<p>
																		{f}{' '}
																		{!escript && (
																			<>
																				<strong>{value}</strong>
																				<br />
																				&nbsp; &nbsp; &nbsp;
																				{row.org__address}, &nbsp; &nbsp; &nbsp;
																				{<a href={'tel:' + row.org__phone}>{row.org__phone}</a>}
																			</>
																		)}
																	</p>
																)) ||
																''
															);
														}}
													</ListBasic.Cell>
													<ListBasic.Cell hideIfEmpty as={Item.Content} row={row} index={index} path="epresc__scid">
														{(value, row, index) => {
															return <p>This is an eScript prescription, click View eScript and show the QR code in your local pharmacy</p>;
														}}
													</ListBasic.Cell>

													{row.script_type == 'cosm' && (
														<Item.Meta>
															Nurse: <ListBasic.Cell hideIfEmpty as={'span'} row={row} index={index} path="usr__displayName" />
														</Item.Meta>
													)}

													{sid &&
														row.script_type == 'pathoreq' &&
														row.status == 'patho_res' &&
														row.patho_results &&
														((row.patho_results.value && (
															<div style={{ marginTop: '3em' }}>
																<PathoResultsPatientReport value={row.patho_results.value} med_db_data={{ m: row.med?.id, ...row.med_db_data }} extra={row} sid={row.sid} />
															</div>
														)) ||
															'Could not load your pathology results. Please contact us.')}
												</Item.Content>

												{
													<Item.Content className="actions">
														{(!row.is_script && !['qualcons', 'qualbuy', 'qualclick'].includes(row.script_type) && (
															<p className="px-4 sm:p-0 mb-2">
																<b>Awaiting Doctor's approval</b>
															</p>
														)) || (
															<>
																{['doccons', 'qualcons', 'qualbuy', 'qualclick'].includes(row.script_type) && !['done_doccall', 'doc_approved'].includes(row.status) && (
																	<>
																		<p className="px-4 sm:p-0">
																			<b>{scripts_list_model.stats_dict(row.status)}</b>
																		</p>
																		<Button color="green" as={Link} to={'/docconsreq/' + row.sid + '/' + row.spd_hash__mobile} content="Consultation details" />
																	</>
																)}

																{['medbuy', 'exprbuy', 'qualbuy'].includes(row.script_type) && row.status == 'to post' && <b>Preparing</b>}

																{['medbuy', 'exprbuy', 'qualbuy'].includes(row.script_type) && row.status == 'shipped' && <b>Shipped</b>}
																{['medsub'].includes(row.script_type) && row.evermed_status && <b>{row.evermed_status}</b>}

																{!isReminderView && ['medcons', 'medbuy', 'medclick', 'exprcons', 'exprbuy', 'exprclick', 'qualcons', 'qualbuy', 'qualclick'].includes(row.script_type) &&
																	row.status === 'doc_approved' &&
																	(user_model.check_access('show_medcat', { k: row.med_db_data__id, cat_nm: 'pharm' }) ||
																		user_model.check_access('show_medcat', { k: row.med_db_data__id, cat_nm: 'patho' })) &&
																	(!row.med_db_data__days_limit * 1 ||
																		moment().diff(moment(row.add_tm, 'D/M/YY HH:mm:ss').startOf('day'), 'days') > row.med_db_data__days_limit * 1 - 2) && (
																		<Button
																			color="green"
																			as={Link}
																			to={'/remed/' + row.med_db_data__pth + '/' + row.med_db_data__id + '/' + row.sid}
																			content={`Reorder ${row.req_type === 'medcert' ? 'Certificate' : 'Script'}`}
																			data-testid="button-reorder"
																		/>
																	)}

																{!escript &&
																	['medcons', 'exprcons', 'qualcons', 'docscr'].includes(row.script_type) &&
																	row.status === 'doc_approved' &&
																	!['medcert'].includes(row.req_type) &&
																	moment().diff(moment(row.add_tm, 'D/M/YY HH:mm:ss'), 'days') < 15 &&
																	!state['resent_' + row.sid] && (
																		<Button
																			color="blue"
																			onClick={async (_) => {
																				const res = await API_service.load_data('scripts/resend_eml', { eml: 'scr_org_confirm', sid: row.sid });
																				setState((prev) => ({ ...prev, ['resent_' + row.sid]: res.res == 'ok' && res.to }));
																			}}
																		>
																			Resend email to the pharmacy
																		</Button>
																	)}

																{!escript &&
																	['medcons', 'exprcons', 'qualcons', 'docscr'].includes(row.script_type) &&
																	row.status === 'doc_approved' &&
																	!['medcert'].includes(row.req_type) &&
																	moment().diff(moment(row.add_tm, 'D/M/YY HH:mm:ss'), 'days') < 7 &&
																	<ReqChangePharm row={row} />}

																{escript && !isReminderView && ['medcons', 'exprcons', 'qualcons', 'docscr'].includes(row.script_type) && (
																	<Button
																		color="green"
																		onClick={() => setViewEscript(escript)}
																		// icon="qrcode"
																		content="View eScript"
																		data-testid="button-view-escript"
																	/>
																)}

																{escript && (
																	<Button primary as={Link} content="Add reminder" to={!onReminderClick && '/reminders/add'} onClick={() => onReminderClick?.(row)} state={row} />
																)}

																{(['pathoreq', 'medcert', 'docrefr', 'doclett', 'sleeprefr', 'medimgreq', 'mentplan', 'mentrev'].includes(row.script_type) ||
																	['medcert'].includes(row.req_type)) &&
																	!row.patho_results && (
																		<PreviewDocument doc_name={pdf_types[row.script_type] || pdf_types[row.req_type] || 'Document'} url={viewScriptUrl(row.sid)}>
																			Download {pdf_types[row.script_type] || pdf_types[row.req_type] || 'Document'}
																		</PreviewDocument>
																	)}
																{['pathoreq', 'medcert', 'docrefr', 'doclett', 'sleeprefr'].includes(row.script_type) && row.patho_results && !sid && (
																	<>
																		{(row.status == 'patho_res' && (
																			<Link className="ui green button" to={`/history/${row.sid}`}>
																				View Pathology Results
																			</Link>
																		)) || <p>Awaiting Results</p>}
																	</>
																)}
															</>
														)}

														{state['resent_' + row.sid] && (
															<>
																Email sent to the pharmacy email ({state['resent_' + row.sid]}).
																<br />
																<b>When you speak to pharmacy staff, please ask them to check their email (not fax) and to also check their junk mail for your prescription.</b>
															</>
														)}

														{/*
												<Button 
													size='small'
													compact
													basic
													as={Link} 
													to={'/chat/'+escape(row.med_db_data__name)}>Join Chat</Button>
													*/}
													</Item.Content>
												}
											</Item>
										);
									}}
								</ListBasic>
							</Item.Group>

							{(sid && (
								<Button as={Link} to={'/history/'}>
									View Full History
								</Button>
							)) || (
								<ListPagination
									page={page}
									pages={pages}
									pageSize={pageSize}
									loading={loading}
									data={data}
									total={total}
									boundaryRange={3}
									showPageSizeSelector={true}
									onPageSizeChange={setPageSize}
									onPageChange={setPage}
								/>
							)}
						</React.Fragment>
					);
				}}
			</DataConnector>
			{view_escript && (
				<Modal closeIcon open onClose={() => setViewEscript(false)} style={{ maxWidth: '400px', padding: '1%' }}>
					<Modal.Header>Scan eScript</Modal.Header>
					<Modal.Content>
						{(view_escript && (
							<React.Fragment>
								<div>
									<QRCode value={view_escript} renderAs="svg" width="100%" height="100%" level="H" />
								</div>
								<div style={{ margin: '2em 0' }}>
									<BarCode value={view_escript} />
								</div>
								<div>
									<svg width="100%" height="100%" viewBox="0 0 20 1">
										<text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="1" fontFamily="monospace">
											DSPID: {view_escript}
										</text>
									</svg>
								</div>
								<p style={{ textAlign: 'center', fontSize: '100%' }}></p>
							</React.Fragment>
						)) || (
							<React.Fragment>
								<h2>Unknown Script ID</h2>
								<p>Could not determine Script ID (SCID).</p>
								<p>Please contact InstantScripts support</p>
							</React.Fragment>
						)}
					</Modal.Content>
				</Modal>
			)}
		</>
	);
}


function ReqChangePharm({ row }) {
	const [state, setState] = useState({});

	return 	!state['phach_sent_'+row.sid] && 
				<Modal
					// style={{maxHeight:'50vh',overflow:'scroll',width:'450px',zIndex:1000}}
					trigger={<Button 
								color='yellow' 
								// size='mini' 
								content = 'Ask to send script to another pharmacy' 
							/>}
					// basic
					// style={{ position: 'static', margin: '0 auto', }}
					closeIcon
					on='click'
					wide='very'
					// position='left center'
					onClose={() => setState((prev) => ({ ...prev, ['phach_'+row.sid] : null, ['phach_notcoll_'+row.sid]: null})) }
					>
						<Modal.Header>Request to send this script to another pharmacy</Modal.Header>
						<Modal.Content >
							This script for {row.med_db_data__name} {row.med_db_data__size} was sent to <b>{row.org__name}</b>, {row.org__address}
							
							<br/><br/>

							<b>If you still didn't collect your medication</b>, you can ask us to send this script to another pharmacy.

							<br/><br/>
							Choose another pharmacy you'd like to send this script to:

							<div className='ui form' style={{paddingBottom:'100px'}}>
								<SearchPharm 
									// error={valids && !valids.sendto_oid}
									onSelect={ (oid, pn, pa, sa, org) => setState((prev) => ({ ...prev, ['phach_'+row.sid]: {
																pha_oid: oid,
																pha_nm:pn, 
																pha_adr:pa, 
																srch_adr:sa
															} }))
												}
									intro={<></>}
									// oid={row.org__oid}
									// noChange={PARTN_PHARM}
									optionHeader={name => <p style={{lineHeight:'1.1em',marginBottom:0}}><strong>{name}</strong></p>}
								/>
							
							</div>

							<br />

							{state['phach_'+row.sid]?.pha_oid && 
								<Checkbox 
									label="I confirm that this script was not collected yet" 
									onChange={ (e, d) => setState((prev) => ({ ...prev, ['phach_notcoll_'+row.sid] : d.checked })) }
									/> }

							<br />
							<br />

							{state['phach_'+row.sid]?.pha_oid && state['phach_notcoll_'+row.sid] && 
								<Button 
										color='green'
										content='Send request'

										onClick={ async _=> {
												const res = await API_service.load_data(
																'scripts/req_cha_pha', 
																{sid: row.sid, oid: state['phach_'+row.sid]?.pha_oid, phach: state['phach_'+row.sid]}
														);
												setState((prev) => ({ ...prev, ['phach_sent_'+row.sid]: res.res=='ok'&&res.to }))
											} }
									/>
							}
						</Modal.Content>
				</Modal>
			|| 	
				<>
					We received your request to change the pickup pharmacy to 
					<br/> 
					<b>{state['phach_'+row.sid].pha_nm}</b>
				</>

}
