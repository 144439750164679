import React from 'react'
import {ExclamationIcon} from "@heroicons/react/outline";

const ExclamationText = ({text}) => {
    return (
        <div className={'flex gap-2 items-center'}>
            <ExclamationIcon className={'max-w-5 text-yellow-500'}/>
            <span className={'text-sm'}>{text}</span>
        </div>
    )
}
export default ExclamationText
