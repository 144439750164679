import React from 'react'
import {Breadcrumb} from "semantic-ui-react";
import CategorySection from "./CategorySection";
import {Link, useParams} from "react-router-dom";
import {capitalize} from "../../../helpers/prettify";

const SearchPage = () => {
    const {searchTerm} = useParams()
    const prettySearchTerm = capitalize(searchTerm)
    const breadCrumbSections = [
        { key: 'Home', content: 'Home', link: true, as: Link, to: '/shop' },
        { key: 'Search', content: 'Search', active: true },
        { key: prettySearchTerm, content: prettySearchTerm, active: true }
    ]

    return (
        <div className={'px-2 md:px-6 py-6 space-y-4'}>
            <Breadcrumb icon='right angle' sections={breadCrumbSections}/>
            <CategorySection search={prettySearchTerm} productsArrayLength={100}/>
        </div>
    )
}
export default SearchPage
