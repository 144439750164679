import React, { Component} from 'react'

import { Button, Header, Icon, Image, Modal, Container, Message } from 'semantic-ui-react'

import UniForm from '../UniForm/UniForm'
import UniFormSUI from '../UniForm/UniForm-SUI'
import contact_model from 'models/contact_model'
import Content from 'views/content'

import ContactForm from 'xAppLib/UIelems/ContactForm'
import ChronicConditionsModal from './ChronicConditionsModal';
import gtm from '../providers/gtm'
import { Link } from 'react-router-dom'
import { ModalBtns } from '../../views/content/MedConsultInfo'

export default class Popups extends Component {

	constructor (props) {
		super(props)

		this.state = {
			modalOpen: false, 
			modal_params: {}
		}


		app.on(app.events.SHOW_POPUP, (p)=>this.show_popup(p))

		app.functions.show_popup = (p)=>this.show_popup(p)

	}

	//	------------------------	------------------------	------------------------

	show_popup(p) {
		if(p.pt === 'contact' && app.site_status?.use_intercom && app.dvc?.org?.type !== 'cosm' && !app.settings.is_cbls && !app.settings.is_cmg){
			window.Intercom('show')
		}
		else{
			this.setState ({
				modalOpen: true,
				modal_params: p,
			})

			gtm.log('', 'modal_open', {modal: p.openCroCondModal ? 'chron_cond' : (p.pn || p.pt || 'default')})
		}
	}

	// 		--------------------------------		--------------------------------		---------

	handleOpen = () => this.setState({ modalOpen: true })

	handleClose = () => {
		this.setState({ modalOpen: false })
		gtm.log('', 'modal_close', {modal: this.state.modal_params.openCroCondModal ? 'chron_cond' : (this.state.modal_params.pn || this.state.modal_params.pt || 'default')})

		if(document.location.pathname.startsWith('/navig') && !app.state.in_sgup_st2)
		{
			app.history.push(app.goto_after_login || '/');
			app.goto_after_login = null;
		}
	}

	// 		--------------------------------		--------------------------------		---------

	componentDidMount () {
	}

	// 		--------------------------------		--------------------------------		---------

	render () {

		const { modalOpen, modal_params } = this.state
		const target = app.settings.is_cmg && 'cmg' || app.settings.is_cbls && 'cbls' || app.settings.icosm && 'cosm' || 'iscr';

		return <div id='Popups-container'>
					{	modalOpen &&
							<React.Fragment>
								<Modal 
									open={modalOpen}
									onClose={this.handleClose}
									style={{ position: 'static', margin: '0 auto', }}
									closeIcon={modal_params.noCloseBtn && !modal_params.keepTopCloseButton ? undefined : true}
									className={`${modal_params.pn} popup_${modal_params.pt||'default'}`}
									data-testid={`modal-${modal_params.pn}`}
                                    size={modal_params.size || ''}
								 >
									{!modal_params.noHeader && <Modal.Header>{modal_params.pt=='contact' && 'Contact our customer support with any question or problem' || modal_params.header_txt ||  modal_params.txt}</Modal.Header>}
									
									{!modal_params.cst_modal && (
										<Modal.Content image scrolling>
											<Modal.Description className="popup-limit">

												{ modal_params.pt=='cx_txt' &&
													<Container 
														className="popup-limit" 
														text 
													>
														<Content name={modal_params.pn} target={target} onClose={this.handleClose} {...modal_params} />
													</Container>
												}
										 		{ modal_params.content_component && React.cloneElement(modal_params.content_component, { onClose: this.handleClose })}
												{ modal_params.pt=='contact' &&
													<ContactForm 
														onClose={this.handleClose} />
												}


											</Modal.Description>
										</Modal.Content>
									)}

									{modal_params.cst_modal && <Content name={modal_params.pn} target={target} onClose={this.handleClose} {...modal_params}/>}

									{modal_params.pt!='contact'&& !modal_params.noCloseBtn && <Modal.Actions>
										<Button 
											secondary 
											content='CLOSE' 
											onClick={this.handleClose}
										 />
									</Modal.Actions>}

									{modal_params.pn==='med-consult' && <ModalBtns close={this.handleClose} info={modal_params}/>}
								</Modal>

							</React.Fragment>
					}

			</div>
	}

}
