import React from 'react';
import { Segment } from 'semantic-ui-react';

export default function ReminderNotificationError({ compact }) {
    return (
        <Segment basic className="!p-0 !m-0">
            <div className={`w-full max-w-3xl mx-auto bg-white rounded-3xl shadow-lg ${compact ? 'p-3 lg:p-4' : 'p-6 lg:p-8'}`}>
                <div className={`text-center ${compact ? 'mb-2' : 'mb-6'}`}>
                    <p className={`flex flex-wrap items-center justify-center gap-2 text-gray-500 ${compact ? 'text-base' : 'text-lg'}`}>
                        There was an error loading this notification.
                    </p>
                </div>
            </div>
        </Segment>
    );
}
