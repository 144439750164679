import React from 'react';
import { Button, Form, Header, Modal } from 'semantic-ui-react';
import { useToggleState } from '../../xAppLib/Hooks';
import ScriptsHistory from '../scripts_history/ScriptsHistory';

export default function AddMedName({ onChange, value, error, fl = {} }) {
	const [isModalOpen, toggleModalOnn, toggleModalOff] = useToggleState();

	const handleOnNameChange = (_, { value: val }) => {
		onChange({ target: { name: fl.name, value: val } });
	};

	return (
		<>
			<div className="flex flex-col md:flex-row md:justify-stretch items-stretch md:items-end gap-2">
				<Form.Input
					label={fl.label}
					required={!fl.valid_not_required}
					name={fl.name}
					value={value}
					onChange={handleOnNameChange}
					placeholder={fl.placeholder}
					error={error}
					data-testid="input-med-name"
					type="text"
					className="!flex-grow !mb-0"
					fluid
				/>
				<Modal className="min-h-[70%]" open={isModalOpen} onOpen={toggleModalOnn} onClose={toggleModalOff} trigger={<Button primary content={fl.content} />} closeIcon>
					<Modal.Header>
						<Header>{fl.modalHeader || 'Scripts History'}</Header>
					</Modal.Header>
					<Modal.Content scrolling>
						<ScriptsHistory isReminderView onReminderClick={toggleModalOff} />
					</Modal.Content>
				</Modal>
			</div>
		</>
	);
}
