import React, { useEffect } from 'react';
import ReminderNotification from './ReminderNotification';
import ReminderNotificationError from './ReminderNotificationError';
import { useAsync, useAsyncUpdate } from 'xAppLib/Hooks/useAsync';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import reminders_model from '../../models/reminders_model';
import { getClientBookingTimezone } from 'views/booking/shared';

export const NOTIFICATION_STATUS = {
	PENDING: 'pending',
	NOTIFIED: 'notified',
	COMPLETED: 'completed',
	SNOOZED: 'snoozed',
	SKIPPED: 'skipped',
}
const CLEAR_MSG = (key) => app.trigger(app.events.CLEAR_MESSAGE, key);

export default function ReminderNotificationController({ notification, compact }) {
	const { reminder_notification_id } = useParams();
	const notificationId = reminder_notification_id || notification.data.reminder_notification_id;
	const {
		loading,
		fn: reload,
		data: { reminders, scheduledTime, scheduledDate, scheduleTimezone } = {},
		error,
	} = useAsync(async () => {
		if (!reminder_notification_id && !notification) return;

		const { data, res } = await reminders_model.get_notification(notificationId);
		if (res === 'ok' && data.reminders) {
			const clientTimezone = getClientBookingTimezone();
			const cre_tm = moment(data?.cre_tm).tz(clientTimezone);
			const scheduledTime = cre_tm.format('h:mma');
			const scheduledDate = cre_tm.format('dddd, MMMM D');
			const scheduleTimezone = clientTimezone.replace('Australia/', '');
			return { reminders: data.reminders, scheduledTime, scheduledDate, scheduleTimezone };
		}
	}, [reminder_notification_id, notification]);

	const updateNotification = useAsyncUpdate(async (status, reminder_notification_detail_id) => {
		const details = {
			status,
			reminder_notification_id: notificationId,
			...(reminder_notification_detail_id && { reminder_notification_detail_id }),
		};
		const result = await reminders_model.update_notification(details);
		if (
			compact
			&& result.res === 'ok'
			&& notification
			&& !reminder_notification_detail_id
		) {
			CLEAR_MSG(notificationId);
		}
		reload();
	}, [notificationId]);

	useEffect(() => {
		if (
			compact
			&& notificationId
			&& reminders
			&& reminders.length > 0
			&& reminders.every(
				reminder =>
					reminder.status !== NOTIFICATION_STATUS.NOTIFIED
					&& reminder.status !== NOTIFICATION_STATUS.PENDING
			)
		) {
			CLEAR_MSG(notificationId);
		}
	}, [JSON.stringify(reminders)]);


	const handleComplete = async ({ reminder_notification_detail_id }) => {
		await updateNotification.fn(NOTIFICATION_STATUS.COMPLETED, reminder_notification_detail_id);
	};

	const handleSkip = async ({ reminder_notification_detail_id }) => {
		await updateNotification.fn(NOTIFICATION_STATUS.SKIPPED, reminder_notification_detail_id);
	};

	const handleSnooze = async ({ reminder_notification_detail_id }) => {
		await updateNotification.fn(NOTIFICATION_STATUS.SNOOZED, reminder_notification_detail_id);
	};

	const handleSnoozeAll = async () => {
		await updateNotification.fn(NOTIFICATION_STATUS.SNOOZED);
	};

	const handleSkipAll = async () => {
		await updateNotification.fn(NOTIFICATION_STATUS.SKIPPED);
	};
	const handleCompleteAll = async () => {
		await updateNotification.fn(NOTIFICATION_STATUS.COMPLETED);
	};

	const handleReminderClick = (reminder) => {
		app.history.push(`/reminders/${reminder.reminder_id}`);
	};

	if (error) {
		return <ReminderNotificationError compact={compact} />;
	}

	return (
		<ReminderNotification
			reminders={reminders}
			scheduledTime={scheduledTime}
			scheduledDate={scheduledDate}
			scheduledTimezone={scheduleTimezone}
			onComplete={handleComplete}
			onSkip={handleSkip}
			onSnooze={handleSnooze}
			onSnoozeAll={handleSnoozeAll}
			onReminderClick={handleReminderClick}
			onSkipAll={handleSkipAll}
			onCompleteAll={handleCompleteAll}
			compact={compact}
			isLoading={loading}
		/>
	);
}
