import moment from "moment-timezone";

export const UI_DATE_FMT = "D/M/YY";
export const UI_DATETIME_FMT = "D/M/YY HH:mm:ss";
export const UI_DATETIME_TZ = "Australia/Melbourne";
export const DATE_TIME_ZONE = "YYYY-MM-DDTHH:mmZ";

export function from_melb_ui_tm(melb_time) {
    return moment.tz(melb_time, UI_DATETIME_FMT, 'Australia/Melbourne').local();
}

export function create_date_interval_array(start, end=moment(), interval='month', format=undefined){
    const interval_arr = []
    while (moment(start) < moment(end)){
        interval_arr.push(moment(start).format(format))
        start = moment(start).add(1, interval)
    }
    return interval_arr
}

export function stateToTimezone(state) {
	switch(state) {
		case 'QLD':
			return 'Australia/Brisbane';
		case 'SA':
			return 'Australia/Adelaide';
		case 'NT':
			return 'Australia/Darwin';
		case 'WA':
			return 'Australia/Perth';
		default:
			return 'Australia/Melbourne';
	}
}

export function textToTimestamp(text) {
	return moment(text).format(DATE_TIME_ZONE);
}

export function timestampToTime(timestamp) {
	if (!timestamp) return;

	const time = moment(timestamp);
	if (time.isValid()) {
		return time.format('HH:mm');
	}
}

export function timeToTimestamp(time) {
	if (!time) return;

  const [hours, minutes] = time.split(':').map(Number);
  if (!hours && hours !== 0 || !minutes && minutes !== 0) return;

  const date = moment().hours(hours).minutes(minutes).seconds(0).milliseconds(0);
	if (date.isValid()) {
		return date.format(DATE_TIME_ZONE);
	}
}

export function getISOByNumber(hour) {
 return moment().startOf('day').add(hour, 'hours').format(DATE_TIME_ZONE);
}
