import React, { useEffect } from 'react';
import { Form, Loader } from 'semantic-ui-react';
import script_model from 'models/script_model';
import UniFormMed from 'views/med/UniFormMed';
import currency from 'currency.js';
import SelectableCard from "../../../UIelems/SelectableCard";
import ExclamationText from "../../../../xAppLib/UIelems/ExclamationText";
import { getSelectedDeliveryMethod } from "../../../../helpers/evermed";

const _DELIVERY_PROVIDER_ICONS = {
  'Australia Post': 'https://storage.googleapis.com/instant-med-public/ecommerce/auspost_icon.png',
  'DoorDash': 'https://storage.googleapis.com/instant-med-public/ecommerce/doordash_icon.png',
  'StarTrack': 'https://storage.googleapis.com/instant-med-public/ecommerce/startrack_icon.png',
  'HDS': 'https://storage.googleapis.com/instant-med-public/ecommerce/hds_icon.png',
  'Be Cool': 'https://storage.googleapis.com/instant-med-public/ecommerce/be-cool_icon.png',
}

const deliveryResErrorLabel = (status) => {
  switch (status) {
    case 'bad_address':
      return 'We cannot retrieve shipping rates for the address listed above. Please check the suburb, state & postcode are correct and try again.';
    default:
      return 'There was an issue getting delivery details for the address listed below. Please try another address or try again later.';
  }
};

const deliveryWithoutATLMessage = <>
  <p>Orders delivered by courier cannot be left unattended. An adult over the age of 18 must be
     available to accept delivery.</p>
  <p>The courier will need to scan the recipient's valid government-issued photo ID into their
     DoorDash app to verify their age.</p>
</>


const EvermedDeliverySection = (props) => {
  const {Section, evermed, inline, hide_section = false, formData, discount, deliveryMethodsToShow} = props;
  const SuggestedProductsInCart = !!formData?.suggested_products?.length;
  const someDeliveryMethodsNotAvailable = deliveryMethodsToShow?.some((method) => !method.suitableForSP);
  const showSuggestedProductsDisclaimer = SuggestedProductsInCart && someDeliveryMethodsNotAvailable;

  return (
      <UniFormMed {...props} section="evermed_delivery" fields={script_model.EM_DELIVERY_FIELDS}>
        {(values, valids, uf_this, fields) => {
          if(!hide_section){
            return (
          <Section>
            <Section.Header className={inline && 'm-0 mb-3 text-md'}>Delivery method</Section.Header>
            {showSuggestedProductsDisclaimer &&
                <ExclamationText text={'Some delivery methods are not available with suggested products.'}/>
            }
            <Section.Content className={inline && 'p-0'} start={!inline}>
              <EvermedDeliveryOptions {...{ values, valids, uf_this, fields, evermed, formData, discount, deliveryMethodsToShow}} />
            </Section.Content>
          </Section>
          )
        }
      }
    }
    </UniFormMed>
  );
};

const EvermedDeliveryOptions = (props) => {
  const { evermed, values, valids, uf_this, discount, formData, deliveryMethodsToShow } = props;

  const SuggestedProductsInCart = !!formData?.suggested_products?.length;

  // If a evermed delivery method has been selected, check if the method is still available after methods refetch.
  // If previously selected options is not in the new delivery methods list, remove previous selection.
  useEffect(() => {
    if (values.delivery && !evermed.loading) {
        if (!deliveryMethodsToShow.find((opt) => opt.code === values.delivery.replace('EM_', ''))) {
            uf_this.handleInputChange(null, { name: 'delivery', value: null });
        }
    }
  }, [evermed?.do]);

  const handleDeliverySelection = async ({ value }) => {
    const selectedMethod = getSelectedDeliveryMethod(evermed, value);
    if(
        !selectedMethod.hasAuthorityToLeave
        && await app.confirm('Important Courier Delivery Information', deliveryWithoutATLMessage, {yes: 'I agree', no: 'Go Back'}) === 'no'
    ) {
      return
    }
    uf_this.handleInputChange(null, { name: 'delivery', value });
  };

  return (
    <Form.Field error={valids && !valids.delivery}>
      <div className={'flex flex-col gap-2'}>
        {!evermed.loading &&
            deliveryMethodsToShow?.length > 0 &&
            deliveryMethodsToShow.map((method) => {
                  // Set price for onepass
                  method.code === 'StandardPost' && discount?.conf?.free_standard_shipping && (method.price = 0)

                  const image = _DELIVERY_PROVIDER_ICONS[method.providerName] || '';
                  const price = currency(method.price);
                  const formattedPrice = price.value === 0 ? 'Free' : price.format();
                  const disableOption = SuggestedProductsInCart && !method.suitableForSP;
                  const displayName = method.type === 'Post'
                      ? `${method.providerName} - ${method.displaySubtext}`
                      : `${method.providerName} ${method.type === 'Courier' && ' Courier'} - ${method.displayName} ${method.displaySubtext}`;

                  return <SelectableCard
                      key={method.code}
                      isSelected={values.delivery === `EM_${method.code}`}
                      onSelect={_=>handleDeliverySelection({value: `EM_${method.code}`})}
                      isInvalid={valids && !valids.delivery}
                      isDisabled={disableOption}
                      cardArgs={{'data-testid': 'card-delivery-method'}}
                  >
                    <div className={'flex justify-between items-center'}>
                      <div className={'flex gap-2 items-center'}>
                        <img src={image} alt={method.providerName} className={'w-8 h-8 rounded-lg'} />
                        <span>{displayName}</span>
                      </div>
                      <span>{formattedPrice}</span>
                    </div>
                  </SelectableCard>
                }
            )}
      </div>
      {evermed.loading && (
          <div className="flex-row space-x-2">
            <Loader inline active size="tiny"/>
            <span>Loading delivery options...</span>
          </div>
      )}
      {!evermed.loading && evermed?.delRes !== 'ok' && <p className="text-red-600 font-semibold">{deliveryResErrorLabel(evermed?.delRes)}</p>}
    </Form.Field>
  );
};

export default EvermedDeliverySection;
