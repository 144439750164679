import React, {useEffect, useMemo, useRef, useState} from 'react'
import ProductCard from "./ProductCard";
import { ProductCardSkeleton } from "./Skeleton";
import {fetchProducts} from "../../../models/ecommerce";
import {useAsync} from "xAppLib/Hooks/useAsync";
import useOnScreen from "xAppLib/Hooks/useOnScreen";
import { cls as clsn } from 'views/NUI/utils'

const CategorySection = ({category = {}, search, sectionName, productsArrayLength, loadingAnchorOffset = 0, fetchFunction}) => {
    const name = sectionName || category.name || `Searched: ${search}`
    const {cid, supplier_id} = category || {}
    const catTerm = cid || search

    const placeholderProducts = new Array(productsArrayLength).fill(null)
    const sectionRef = useRef(null)
    const isIntersecting = useOnScreen(sectionRef)
    const [hasFetched, setHasFetched] = useState(false)

    const shouldFetch = useMemo(() =>
        (fetchFunction || (!fetchFunction && (supplier_id || search)))
        && (!hasFetched || hasFetched !== catTerm)
        && (!loadingAnchorOffset || (loadingAnchorOffset && isIntersecting)
    ), [isIntersecting, hasFetched, catTerm, fetchFunction])

    const {data: products = [], loading: loadingProducts = true, error } = useAsync(
    {
        fn: async () => {
            setHasFetched(catTerm)
            const fetchParams = {cid, ps: productsArrayLength, search, supplier_id: supplier_id || search && 'vkHODYE'}
            return await fetchFunction?.(fetchParams) || fetchProducts(fetchParams)
        },
        keep: true,
        immediate: shouldFetch === true

    }, [catTerm])

    useEffect(() => {
        catTerm !== hasFetched && setHasFetched(false)
    }, [catTerm]);

    if(error || (!products?.length && !loadingProducts)) {
        return <div className={'gap-4'}>
            <div className={'relative transparent w-1 h-1 top-0'} ref={sectionRef} />
            <h4 className={'mt-2 mb-1'}>{name}</h4>
            <p className={'text-gray-700'}>{error || 'No products found'}</p>
        </div>
    }

    return (
        <div>
            {/* Offset section to trigger fetch of section to help seamless loading*/}
            {/* TODO - The dynamic offset probably needs to be fixed in tailwinds config to allow dynamic classes, for now it's fine and the below hack will work. */}
            <div className={'invisible -top-[100px] -top-[200px] -top-[300px] -top-[400px] -top-[500px] -top-[600px] -top-[700px] -top-[800px] -top-[800px] -top-[1000px]'}></div>

            <div className={clsn(loadingAnchorOffset ? `-top-[${loadingAnchorOffset}px]` : 'top-0', 'relative transparent w-1 h-1')} ref={sectionRef}/>
            <h4>{name}</h4>
            <div className={'grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-6 gap-4'}>
                {
                    (loadingProducts || !products.length)
                        ? placeholderProducts.map((_, i) => <ProductCardSkeleton key={i}/>)
                        : products.slice(0, productsArrayLength).map((product, i) =>
                            <ProductCard
                                key={product.id}
                                product={product}
                                allowMultiple={true}
                            />)
                }
            </div>
        </div>
    )
}

export default CategorySection
